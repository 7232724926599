import { createSlice, Dispatch } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import { CLIENT_API_PREFIX } from "../../utils/constants";
import { data, InitialMyAppointmentState } from "../common/common";
import { IScheduleAppointment } from "./type/appointment";

const initialState: InitialMyAppointmentState = {
  isLoading: false,
  error: null,
  payload: data,
  appointmentListForClient: {
    data: [],
  },
  isAppointmentCrated: false,
};

const slice = createSlice({
  name: "myAppointmentSlice",
  initialState,
  reducers: {
    // START LOADING
    startMyAppointmentLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasMyAppointmentError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET APPOINTMENTS
    getMyAppointmentList(state, action) {
      state.isLoading = false;
      state.appointmentListForClient = action.payload;
      state.isAppointmentCrated = false;
      state.payload = null;
    },

    // CREATE APPOINTMENT FOR CLIENT
    createAppointmentForClient(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
      state.isAppointmentCrated = true;
    },

    // MARK WEATHER MEETIGN WAS HOLD OR NOT
    updateAppointmentHoldOrNotForClient(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
    },
  },
});

//Reducer
export default slice.reducer;

const {
  startMyAppointmentLoading,
  hasMyAppointmentError,
  getMyAppointmentList,
  createAppointmentForClient,
  updateAppointmentHoldOrNotForClient,
} = slice.actions;

//-------------------------------------------------------------------------------------------------------------------------

// get appointment list
export function getAllAppointmentsForClient() {
  return async (dispatch: Dispatch) => {
    dispatch(startMyAppointmentLoading());
    try {
      const response = await axiosInstance.get(
        `${CLIENT_API_PREFIX}/my/appointment`,
      );
      if (response.data) {
        dispatch(getMyAppointmentList(response.data));
      }
    } catch (error) {
      dispatch(hasMyAppointmentError(error));
    }
  };
}

//---------------------------------------------------------------------------------------------------------------------

// create appointment
export function scheduleAppointmentForClient(
  appointmentData: IScheduleAppointment,
) {
  return async (dispatch: Dispatch) => {
    dispatch(startMyAppointmentLoading());
    try {
      const response = await axiosInstance.post(
        `${CLIENT_API_PREFIX}/create/appointment`,
        {
          date: appointmentData.date,
          practiceArea: appointmentData.practiceArea,
          preferredTime: appointmentData.preferredTime,
          question: appointmentData.question,
        },
      );
      dispatch(createAppointmentForClient(response.data));
    } catch (error) {
      dispatch(hasMyAppointmentError(error));
    }
  };
}

//---------------------------------------------------------------------------------------------------------------------
// update weather appointment was hold or not
export function updateWeatherMeetingWasHoldOrNotByClient(
  appointmentId: number,
  isMeetingHoldClient: boolean,
) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axiosInstance.put(
        `${CLIENT_API_PREFIX}/update/appointment/isMeetingHoldClient/${appointmentId}?isMeetingHoldClient=${isMeetingHoldClient}`,
      );
      if (response.data) {
        dispatch(updateAppointmentHoldOrNotForClient(response.data));
      }
    } catch (error) {
      dispatch(hasMyAppointmentError(error));
    }
  };
}

//--------------------------------------------------------------------------------------------------------------------------
