import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { data } from "autoprefixer";
import axiosInstance from "../../utils/axios";
import { ATTORNEY_API_PREFIX } from "../../utils/constants";
import { InitialAttorneyAppointmentState } from "../common/common";

const initialState: InitialAttorneyAppointmentState = {
  isLoading: false,
  error: null,
  payload: data,
  assignedAppointmentList: {
    data: [],
  },
  acceptedAppointmentList: {
    data: [],
  },
  appointmentCount: {
    data: {
      assignedAppointmentCount: 0,
      acceptedAppointmentCount: 0,
    },
  },
};

const slice = createSlice({
  name: "attorneyAppointmentSlice",
  initialState,
  reducers: {
    // START LOADING
    startAttorneyAppointmentLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasAttorneyAppointmentError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET APPOINTMENTS
    getAssignedAppointmentList(state, action) {
      state.isLoading = false;
      state.assignedAppointmentList = action.payload;
      state.payload = null;
    },

    // GET ACCEPTED APPOINTMENT
    getAcceptedAppointmentList(state, action) {
      state.isLoading = false;
      state.acceptedAppointmentList = action.payload;
      state.payload = null;
    },

    // GET LEADS COUNT
    getAppointmentCount(state, action) {
      state.isLoading = false;
      state.appointmentCount = action.payload;
      state.payload = null;
    },

    // ACCEPT AN APPOINTMENT
    acceptAppointment(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
    },

    // REJECT AN APPOINTMENT
    rejectAppointment(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
    },

    // MARK WEATHER MEETIGN WAS HOLD OR NOT
    updateAppointmentHoldOrNotForAttorney(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
    },
  },
});

export default slice.reducer;

const {
  startAttorneyAppointmentLoading,
  hasAttorneyAppointmentError,
  getAssignedAppointmentList,
  getAcceptedAppointmentList,
  getAppointmentCount,
  acceptAppointment,
  rejectAppointment,
  updateAppointmentHoldOrNotForAttorney,
} = slice.actions;

//--------------------------------------------------------------

// get assigned appointments
export function getAssignedAppointmentData() {
  return async (dispatch: Dispatch) => {
    dispatch(startAttorneyAppointmentLoading());
    try {
      const response = await axiosInstance.get(
        `${ATTORNEY_API_PREFIX}/assigned/appointment`,
      );
      if (response.data.data) {
        dispatch(getAssignedAppointmentList(response.data));
      }
    } catch (error) {
      dispatch(hasAttorneyAppointmentError(error));
    }
  };
}

//---------------------------------------------------------------------------------------------------------------------

// get accepted appointments
export function getAcceptedAppointmentData() {
  return async (dispatch: Dispatch) => {
    dispatch(startAttorneyAppointmentLoading());
    try {
      const response = await axiosInstance.get(
        `${ATTORNEY_API_PREFIX}/accepted/appointment`,
      );
      if (response.data.data) {
        dispatch(getAcceptedAppointmentList(response.data));
      }
    } catch (error) {
      dispatch(hasAttorneyAppointmentError(error));
    }
  };
}

//---------------------------------------------------------------------------------------------------------------------

// get appointment count
export function getAttorneyAppointmentCount() {
  return async (dispatch: Dispatch) => {
    dispatch(startAttorneyAppointmentLoading());
    try {
      const response = await axiosInstance.get(
        `${ATTORNEY_API_PREFIX}/appointment/count`,
      );
      if (response.data.data) {
        dispatch(getAppointmentCount(response.data));
      }
    } catch (error) {
      dispatch(hasAttorneyAppointmentError(error));
    }
  };
}

//--------------------------------------------------------------------------------------------------------------------------

// accpet an appointment
export function acceptClientAppointment(appointmentId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axiosInstance.put(
        `${ATTORNEY_API_PREFIX}/accept/appointment/${appointmentId}`,
      );
      if (response.data) {
        dispatch(acceptAppointment(response.data));
      }
    } catch (error) {
      dispatch(hasAttorneyAppointmentError(error));
    }
  };
}

//--------------------------------------------------------------------------------------------------------------------------

// reject an appointment
export function rejectClientAppointment(appointmentId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axiosInstance.put(
        `${ATTORNEY_API_PREFIX}/reject/appointment/${appointmentId}`,
      );
      if (response.data) {
        dispatch(rejectAppointment(response.data));
      }
    } catch (error) {
      dispatch(hasAttorneyAppointmentError(error));
    }
  };
}

//--------------------------------------------------------------------------------------------------------------------------
// update weather appointment was hold or not
export function updateWeatherMeetingWasHoldOrNotByAttorney(
  appointmentId: number,
  isMeetingHoldAttorney: boolean,
) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axiosInstance.put(
        `${ATTORNEY_API_PREFIX}/update/appointment/isMeetingHoldAttorney/${appointmentId}?isMeetingHoldAttorney=${isMeetingHoldAttorney}`,
      );
      if (response.data) {
        dispatch(updateAppointmentHoldOrNotForAttorney(response.data));
      }
    } catch (error) {
      dispatch(hasAttorneyAppointmentError(error));
    }
  };
}

//--------------------------------------------------------------------------------------------------------------------------
