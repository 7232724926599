export const ROOT_LOGIN = "/login";
export const SIGN_UP = "/register";
export const FORGOT_PASSWORD_OPTIONS = "/forgot-password/options";
export const FORGOT_PASSWORD = "/forgot-password";
export const FORGOT_PASSWORD_OTP = "/forgot-password/otp";
export const VERIFY_OTP = "/verify-otp";
export const RESET_PASSWORD = "/reset-password";
export const CLIENT_PAYMENT = "/client-payment";
export const PAYMENT_SUCCESS = "/payment-success";
export const ROOT_IMMIGRATION_QUESTION = "/immigration-question";
export const LEAD_CLAIMED_SUCCESSFULLY = "/lead-claimed";
export const APPOINTMENT_FEEDBACK = "/appointment/feedback";
export const USER_CONSENT = "/user/consent";

export enum Tabs {
  HOME = "home",
  ASK_QUESTION = "ask-question",
  MY_QUESTIONS = "my-questions",
  ATTORNEY_NOTIFICATIONS = "attorney-notifications",
  LEADS_DETAILS = "leads-details",
  CLIENT_PROFILE = "client-profile",
  ATTORNEY_PROFILE = "attorney-profile",
  MY_APPOINTMENTS = "my-appointments",
  GO_TO_MYLS = "go-to-myls",
  ATTORNEY_APPOINTMENTS = "attorney-appointments",
}
